import pagarme from 'pagarme';

interface Card {
  holder_name: string;
  number: string;
  expiration_date: string;
  cvv: string;
}

export default async function validateCard(card: Card): Promise<string | null> {
  const cardDetails = pagarme.validate({
    card: {
      card_holder_name: card.holder_name,
      card_number: card.number,
      card_expiration_date: card.expiration_date,
      card_cvv: card.cvv,
    },
  });

  if (!cardDetails.card) {
    return 'Não foi possível validar os dados do cartão.';
  }
  if (!cardDetails.card.card_number) {
    return 'Número do cartão inválido.';
  }
  if (!cardDetails.card.card_holder_name) {
    return 'Nome do portador do cartão inválido.';
  }
  if (!cardDetails.card.card_expiration_date) {
    return 'Data de expiração do cartão inválida.';
  }
  if (!cardDetails.card.card_cvv) {
    return 'Código de segurança do cartão inválido.';
  }

  return null;
}

/* eslint-disable import/no-duplicates */
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export default function formatDate(date: Date | string): string | undefined {
  let receivedDate = date;

  if (!receivedDate) {
    return undefined;
  }

  if (typeof receivedDate === 'string') {
    receivedDate = parseISO(receivedDate);
  }

  return format(receivedDate, "d 'de' MMMM 'de' yyyy", {
    locale: ptBR,
  });
}

import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../components/Input';

import logoImg from '../../assets/logo.png';

import {
  Container,
  Content,
  AnimationContainer,
  SubmitButton,
  BackToLoginContainer,
  Background,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

interface SignInFormData {
  name: string;
  email: string;
  password: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const { signUp } = useAuth();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          name: Yup.string().required(),
          email: Yup.string()
            .email('Formato inválido')
            .required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signUp({
          name: data.name,
          email: data.email,
          password: data.password,
        });

        history.push('/signup/successful-registration');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'E-mail já cadastrado.',
          });
        }

        setLoading(false);
      }
    },
    [signUp, history, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h1>Cadastro</h1>

          <p>Preencha os dados abaixo para começar.</p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="name" type="text" placeholder="Nome completo" />
            <Input name="email" type="email" placeholder="E-mail" />
            <Input name="password" type="password" placeholder="Senha" />

            <SubmitButton type="submit" disabled={isLoading}>
              Concluir cadastro
            </SubmitButton>
          </Form>

          <BackToLoginContainer>
            <span>Já possui uma conta?</span>

            <Link to="/login">Voltar para login</Link>
          </BackToLoginContainer>
        </AnimationContainer>
      </Content>

      <Background>
        <img src={logoImg} alt="Jana Jô Moda Empresarial &amp; Universitária" />
      </Background>
    </Container>
  );
};

export default SignUp;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

export interface CartItem {
  product_id: string;
  size: string;
  quantity: number;
}

interface CartContextData {
  cart: CartItem[];
  total: number;
  setTotal(total: number): void;
  addItem(product_id: string, size: string): void;
  setItemSize(product_id: string, size: string): void;
  incrementItem(product_id: string): void;
  decrementItem(product_id: string): void;
  clearCart(): void;
}

const CartContext = createContext<CartContextData>({} as CartContextData);

const CartProvider: React.FC = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>([]);
  const [cartTotal, setCartTotal] = useState<number>(0);

  useEffect(() => {
    const storagedCart = localStorage.getItem('@JanaJo:cart');

    if (storagedCart) {
      setCart(JSON.parse(storagedCart));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('@JanaJo:cart', JSON.stringify(cart));
  }, [cart]);

  const setTotal = useCallback((total: number) => {
    if (total < 0) {
      throw new Error('Total must be a positive number');
    }

    setCartTotal(total);
  }, []);

  const addItem = useCallback((product_id: string, size: string) => {
    const newItem = {
      product_id,
      size,
      quantity: 1,
    };

    setCart(oldCart => [...oldCart, newItem]);
  }, []);

  const setItemSize = useCallback(
    (product_id, size) => {
      const itemsArray = [...cart];

      const item = itemsArray.find(_item => _item.product_id === product_id);

      if (!item) {
        return;
      }

      item.size = size;

      setCart(itemsArray);
    },
    [cart],
  );

  const incrementItem = useCallback(
    product_id => {
      const itemsArray = [...cart];

      const item = itemsArray.find(_item => _item.product_id === product_id);

      if (!item) {
        return;
      }

      if (item.quantity < 99) {
        item.quantity += 1;
      }

      setCart(itemsArray);
    },
    [cart],
  );

  const decrementItem = useCallback(
    product_id => {
      const itemsArray = [...cart];

      const item = itemsArray.find(_item => _item.product_id === product_id);

      if (!item) {
        return;
      }

      if (item.quantity > 0) {
        item.quantity -= 1;

        if (item.quantity === 0) {
          const itemIndex = itemsArray.findIndex(
            _item => _item.product_id === item.product_id,
          );

          itemsArray.splice(itemIndex, 1);
        }
      }

      setCart(itemsArray);
    },
    [cart],
  );

  const clearCart = useCallback(() => {
    setCart([]);
    setCartTotal(0);
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        total: cartTotal,
        setTotal,
        addItem,
        setItemSize,
        incrementItem,
        decrementItem,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

function useCart(): CartContextData {
  const context = useContext(CartContext);

  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }

  return context;
}

export { CartProvider, useCart };

import styled, { keyframes } from 'styled-components';
import { Button } from '../../styles/Button';

export const Container = styled.div`
  display: flex;
  align-items: stretch;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  animation: ${appearFromLeft} 0.68s;

  h1 {
    font-size: 32px;
    max-width: 260px;
  }

  > p {
    margin-top: 8px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 36px 0;
    width: 360px;

    > a {
      text-decoration: none;
      color: inherit;
      margin: 24px 0;
    }
  }

  @media (max-width: 960px) {
    form {
      max-width: 320px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 28px;
`;

export const BackToLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  > a {
    font-weight: 600;
    color: #6a6a6a;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Background = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--color-gray);
  flex: 1;

  img {
    max-width: 300px;
    animation: ${fadeIn} 1s;
  }

  @media (max-width: 960px) {
    display: none;
  }
`;

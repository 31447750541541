import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../components/Input';

import logoImg from '../../assets/logo.png';

import {
  Container,
  Content,
  AnimationContainer,
  SubmitButton,
  BackToLoginContainer,
  Background,
} from './styles';

import { useToast } from '../../hooks/toast';
import { useQuery } from '../../hooks/query';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface ResetPasswordFormData {
  password: string;
}

const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();

  const queryEmail = useQuery().get('email');
  const queryToken = useQuery().get('token');
  const history = useHistory();

  const handleSubmit = useCallback(
    async (data: ResetPasswordFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('users/password/reset', {
          token: queryToken,
          password: data.password,
        });

        addToast({
          type: 'success',
          title: 'Senha redefinida com sucesso.',
        });

        history.push(`/?email=${queryEmail}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Usuário não encontrado.',
          });
        }

        setLoading(false);
      }
    },
    [queryToken, queryEmail, history, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h1>Resetar senha</h1>

          <p>Preencha o campo abaixo com uma nova senha</p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="password" type="password" placeholder="Senha" />

            <SubmitButton type="submit" disabled={isLoading}>
              Resetar senha
            </SubmitButton>
          </Form>

          <BackToLoginContainer>
            <Link to="/login">Voltar para login</Link>
          </BackToLoginContainer>
        </AnimationContainer>
      </Content>

      <Background>
        <img src={logoImg} alt="Jana Jô Moda Empresarial &amp; Universitária" />
      </Background>
    </Container>
  );
};

export default ResetPassword;

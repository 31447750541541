import styled from 'styled-components';
import { Button } from '../../styles/Button';

export const Container = styled.div`
  > header {
    > div {
      display: flex;
      max-width: 960px;
      margin: 0 auto;
      padding: 40px 20px 80px;

      @media (max-width: 640px) {
        flex-direction: column;
      }
    }

    width: 100%;
    background-color: var(--color-gray);

    nav {
      ul {
        display: flex;
        list-style: none;
        font-size: 18px;

        li + li {
          margin-left: 48px;
        }

        a {
          color: inherit;
          text-decoration: none;
          transition: 0.1s;
          padding: 4px;

          &:hover {
            text-decoration: underline;
            color: var(--text-subtitle);
          }
        }
      }

      @media (max-width: 640px) {
        display: flex;
        align-items: center;

        margin-top: 32px;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
  }
`;

export const HeaderContent = styled.div`
  flex: 1;

  margin-bottom: 24px;

  h1 {
    color: #fff;
    max-width: 350px;
  }

  h2 {
    color: var(--text-subtitle);
    max-width: 400px;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const FormContent = styled.div`
  max-width: 960px;
  margin: 0 auto;

  background: #fff;
  margin-top: -48px;
  padding: 48px 0 0;
  border-radius: 8px;

  fieldset {
    display: flex;
    flex-direction: column;
    padding: 0 64px;
    border: 0;

    @media (max-width: 640px) {
      padding: 0 24px;
    }

    & + fieldset {
      margin-top: 36px;
    }

    legend {
      font-weight: 600;
      font-size: 24px;
      color: var(--color-text-title);
      margin-bottom: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--color-line-in-white);
    }

    > label {
      display: flex;
      flex-direction: column;

      & + label {
        margin-top: 24px;
      }
    }

    label span {
      margin: 0 0 4px 8px;
    }

    input:read-only {
      background-color: #f7f7f7;
    }
  }

  select,
  input {
    padding: 18px 26px;
    border: 1px solid #e6e6f0;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
  }

  footer {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 48px 64px;
    margin-top: 96px;

    background: var(--color-box-footer);
    border-top: 1px solid var(--color-line-in-white);

    @media (max-width: 640px) {
      flex-direction: column;
      margin-top: 32px;
    }

    > div {
      display: flex;

      > img {
        margin-right: 16px;
      }
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0;

  & + div {
    margin-top: 0;
  }

  > label {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + label {
      margin-left: 24px;
    }
  }

  @media (max-width: 960px) {
    flex-direction: column;

    > label + label {
      margin-left: 0;
      margin-top: 24px;
    }
  }
`;

export const SubmitButton = styled(Button)`
  padding: 20px 32px;
  font-size: 18px;

  @media (max-width: 640px) {
    margin-top: 24px;
  }
`;

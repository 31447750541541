import styled, { css } from 'styled-components';

interface InputProps {
  errored: number;
}

export const InputContainer = styled.input<InputProps>`
  padding: 18px 26px;
  border: 1px solid #e6e6f0;
  border-radius: 4px 4px 0 0;

  & + input {
    border-radius: 0 0 4px 4px;
  }

  &::placeholder {
    color: #9c98a6;
  }

  ${props =>
    props.errored &&
    css`
      border-color: #e33d3d !important;
    `}
`;

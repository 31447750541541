import { createGlobalStyle } from 'styled-components';
import { shade } from 'polished';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  body {
    background: var(--color-background);
    color: var(--text-primary);
    -webkit-font-smoothing: antialiased;
  }

  body, input, select, button {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    color: var(--text-title);
  }

  button {
    cursor: pointer;
  }

  :root {
    --color-gray: #35363b;
    --color-another-gray: #404147;
    --color-delete: #e33d3d;
    --color-delete-hover: ${shade(0.2, '#e33d3d')};
    --color-green: #04d361;
    --color-green-hover: ${shade(0.2, '#04d361')};
    --color-yellow: #e3c454;
    --color-yellow-hover: ${shade(0.2, '#e3c454')};
    --color-background: #f0f0f7;
    --text-title: #32264d;
    --text-subtitle: #b0b0b7;
    --text-primary: #6a6180;
    --text-secondary: #b0b0b7;
    --color-line-in-white: #e6e6f0;
    --color-box-footer: #fafafc;
  }
`;

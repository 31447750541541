import React, { useCallback, useEffect, useState, MouseEvent } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';
import {
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdDescription,
  MdPictureAsPdf,
} from 'react-icons/md';

import {
  Container,
  HeaderContent,
  GroupDetailsContainer,
  LoadingContainer,
  ProductsContainer,
  OptionsContainer,
  OptionButton,
  ProductsGrid,
  Product,
  Counter,
  CounterButton,
  SubmitButton,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { useCart } from '../../hooks/cart';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import formatBRL from '../../utils/formatBRL';
import formatDate from '../../utils/formatDate';

interface Group {
  id: string;
  name: string;
  end_date: string;
  measurement_table_url?: string;
}
interface Product {
  id: string;
  name: string;
  price: number;
  image_url: string;
  measurements_url: string;
  available_sizes: string[];
}

const Products: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [products, setProducts] = useState<Product[]>();
  const [group, setGroup] = useState<Group>({} as Group);
  const history = useHistory();

  const { user, signOut } = useAuth();
  const { addToast } = useToast();
  const {
    cart,
    total,
    setTotal,
    addItem,
    setItemSize,
    incrementItem,
    decrementItem,
  } = useCart();

  useEffect(() => {
    async function loadProducts() {
      setLoading(true);

      try {
        const getProductsResponse = await api.get<Product[]>(
          `/me/group/products`,
        );

        const productsData = getProductsResponse.data;

        setProducts(productsData);

        const showGroupDetailsResponse = await api.get<Group>('/me/group');

        setGroup(showGroupDetailsResponse.data);
      } catch {
        addToast({
          type: 'error',
          title:
            'Não é possível realizar um novo pedido, a sua turma está fechada.',
        });

        const response = await api.get('me');

        const userDetails = response.data;

        if (!userDetails.group_id) {
          history.push('/join-group');
          return;
        }

        history.push('/orders');
      } finally {
        setLoading(false);
      }
    }

    if (!user.group_id) {
      history.push('/join-group');
      return;
    }

    loadProducts();
  }, [history, user.group_id, addToast]);

  const handleIncrementItem = useCallback(
    product_id => {
      if (products) {
        const product = products.find(_product => _product.id === product_id);

        if (!product) {
          return;
        }

        const isProductAdded = cart.find(
          item => item.product_id === product?.id,
        );

        if (isProductAdded) {
          incrementItem(product.id);
        } else {
          addItem(product.id, product.available_sizes[0]);
        }
      }
    },
    [products, cart, incrementItem, addItem],
  );

  const cartItemExists = useCallback(
    (product_id: string) => {
      const item = cart.find(_item => _item.product_id === product_id);

      return !!item;
    },
    [cart],
  );

  const getCartItemSize = useCallback(
    (product_id: string) => {
      const item = cart.find(_item => _item.product_id === product_id);

      return item?.size;
    },
    [cart],
  );

  useEffect(() => {
    const cartTotal = cart.reduce((acc, item) => {
      const product = products?.find(
        _product => _product.id === item.product_id,
      );

      if (product) {
        return product?.price * item.quantity + acc;
      }

      return acc;
    }, 0);

    setTotal(cartTotal);
  }, [cart, products, setTotal]);

  const handleSubmit = useCallback(async () => {
    history.push('/orders/checkout');
  }, [history]);

  const handleOpenInstructions = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.preventDefault();

      history.push('/instructions');
    },
    [history],
  );

  return (
    <Container>
      <header>
        <div>
          <HeaderContent>
            <h1>Estes são os produtos disponíveis.</h1>
            <h2>
              Basta selecionar o tamanho e quantidade dos produtos desejados e
              finalizar o seu pedido!
            </h2>
          </HeaderContent>

          <nav>
            <ul>
              <li>
                <Link to="/orders">Meus Pedidos</Link>
              </li>

              <li>
                <Link to="/login" onClick={signOut}>
                  Sair
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {isLoading ? (
        <LoadingContainer>
          <ReactLoading type="bubbles" color="#6a6180" />
        </LoadingContainer>
      ) : (
        <ProductsContainer>
          {products && products.length > 0 ? (
            <>
              <GroupDetailsContainer>
                <div>
                  <strong>Turma: </strong>
                  {group.name}
                </div>

                <div>
                  <strong>Data de fechamento: </strong>
                  {formatDate(group.end_date)}
                </div>
              </GroupDetailsContainer>

              <OptionsContainer>
                <OptionButton
                  href="/instructions"
                  onClick={handleOpenInstructions}
                >
                  Instruções
                  <MdDescription />
                </OptionButton>

                {group.measurement_table_url && (
                  <OptionButton
                    href={group.measurement_table_url}
                    target="__blank"
                  >
                    Tabela de medidas
                    <MdPictureAsPdf />
                  </OptionButton>
                )}
              </OptionsContainer>

              <ProductsGrid>
                {products?.map(product => (
                  <Product
                    key={product.id}
                    isSelected={cartItemExists(product.id)}
                  >
                    <header>
                      <img src={product.image_url} alt={product.name} />
                      <span>{product.name}</span>
                    </header>

                    <main>
                      <div>
                        <span>Tamanho: </span>
                        <select
                          disabled={!cartItemExists(product.id)}
                          onChange={e =>
                            setItemSize(product.id, e.target.value)
                          }
                          value={
                            getCartItemSize(product.id) ||
                            product.available_sizes[0]
                          }
                        >
                          {product.available_sizes.map((size, index) => (
                            <option key={`${index}-${size}`} value={size}>
                              {size}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div>
                        <span>
                          Preço: <strong>{formatBRL(product.price)}</strong>
                        </span>
                      </div>

                      {product.measurements_url && (
                        <div>
                          <a href={product.measurements_url}>
                            Visualizar medidas
                          </a>
                        </div>
                      )}
                    </main>

                    <Counter>
                      <CounterButton
                        type="button"
                        action="decrease"
                        onClick={() => decrementItem(product.id)}
                      >
                        <MdKeyboardArrowLeft size={24} color="#fff" />
                      </CounterButton>

                      <div>
                        {cart.find(item => item.product_id === product.id)
                          ?.quantity || 0}
                      </div>

                      <CounterButton
                        type="button"
                        action="increase"
                        onClick={() => handleIncrementItem(product.id)}
                      >
                        <MdKeyboardArrowRight size={24} color="#fff" />
                      </CounterButton>
                    </Counter>
                  </Product>
                ))}
              </ProductsGrid>

              <footer>
                <div>
                  <span>
                    Total: <strong>{formatBRL(total || 0)}</strong>
                  </span>
                </div>

                <SubmitButton
                  type="button"
                  disabled={cart.length < 1}
                  onClick={handleSubmit}
                >
                  Finalizar pedido
                </SubmitButton>
              </footer>
            </>
          ) : (
            <p>Ops... Não há nada para ver aqui :/</p>
          )}
        </ProductsContainer>
      )}

      <footer>
        <p>Copyright &copy; Dominick Brasileiro 2021</p>
      </footer>
    </Container>
  );
};

export default Products;

import React from 'react';
import { Link } from 'react-router-dom';

import { Container, Content, Section, ButtonContainer } from './styles';

const Instructions: React.FC = () => {
  return (
    <Container>
      <Content>
        <h1>Instruções:</h1>

        <Section>
          <h2>
            <span role="img" aria-label="finger emoji">
              👉
            </span>{' '}
            Tamanhos
          </h2>
          <ul>
            <li>
              Não será aceito <strong>NENHUMA</strong> troca de tamanho após o
              pedido ser confirmado e/ou recebido. (Por isso observe bem a
              tabela de medidas, e qualquer dúvida que tiver, tire antes de
              efetuar a compra).
            </li>
            <li>
              Cada empresa tem suas medidas próprias, por isso analise a tabela
              base da Jana Jô, e não apenas a etiqueta de alguma peça sua.
            </li>
          </ul>
        </Section>

        <Section>
          <h2>
            <span role="img" aria-label="finger emoji">
              👉
            </span>{' '}
            Pagamento
          </h2>
          <ul>
            <li>
              Ao finalizar o pedido você terá as seguintes opções de pagamento:
              <ul className="sub">
                <li>Via boleto;</li>
                <li>
                  Via cartão de crédito em até 3 vezes sem juros. (Parcela
                  mínima R$ 50,00). Aproveite essa vantagem para comprar todas
                  as peças que desejar, usar muito e depois guardá-las como
                  lembrança.
                </li>
              </ul>
            </li>
          </ul>
        </Section>

        <Section>
          <h2>
            <span role="img" aria-label="finger emoji">
              👉
            </span>{' '}
            Prazo
          </h2>
          <ul>
            <li>
              O pagamento de todos os pedidos deverá ser realizado até a data de
              fechamento da turma. Não será possível cadastrar mais pedidos e
              efetuar pagamentos após este prazo.
            </li>
          </ul>
        </Section>

        <ButtonContainer>
          <Link to="/" className="button">
            Ok, Entendi!
          </Link>
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export default Instructions;

import styled from 'styled-components';

export const Button = styled.button`
  background-color: var(--color-green);
  font-size: 'Archivo', sans-serif;
  font-weight: 500;
  padding: 14px 0;
  border: none;
  outline: none;
  border-radius: 4px;
  color: #fff;
  transition: 0.2s background-color;

  &:hover {
    background-color: var(--color-green-hover);
  }

  &:disabled {
    background-color: #dcdce5;
    color: #9c98a6;
    cursor: not-allowed;

    &:hover {
      background-color: #dcdce5;
    }
  }
`;

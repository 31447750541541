import React from 'react';

import { ToastProvider } from './toast';
import { AuthProvider } from './auth';
import { CartProvider } from './cart';

const AppProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider>
      <AuthProvider>
        <CartProvider>{children}</CartProvider>
      </AuthProvider>
    </ToastProvider>
  );
};

export default AppProvider;

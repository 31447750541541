import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Container, RedirectButton } from './styles';
import DoneIcon from '../../assets/done.svg';
import ErrorIcon from '../../assets/error.svg';

import { useQuery } from '../../hooks/query';
import api from '../../services/api';

const SuccessfulRegistration: React.FC = () => {
  const [isLoading, setLoading] = useState(true);
  const [errored, setErrored] = useState(false);

  const queryToken = useQuery().get('token');
  const queryEmail = useQuery().get('email');
  const history = useHistory();

  useEffect(() => {
    async function verifyAccount() {
      try {
        await api.post('users/verify', {
          token: queryToken,
        });
      } catch {
        setErrored(true);
      }

      setLoading(false);
    }

    verifyAccount();
  }, [queryToken]);

  return (
    <Container>
      {!isLoading && (
        <>
          {errored ? (
            <>
              <img src={ErrorIcon} alt="Feito" />
              <h1>Algo deu errado</h1>

              <p>
                Houve um erro ao tentar confirmar o seu e-mail.
                <br />
                Por favor, tente novamente mais tarde.
              </p>
            </>
          ) : (
            <>
              <img src={DoneIcon} alt="Feito" />
              <h1>E-mail confirmado</h1>

              <p>
                Agora você faz parte da nossa plataforma.
                <br />
                Tenha uma ótima experiência.
              </p>

              <RedirectButton
                onClick={() => history.push(`/login?email=${queryEmail}`)}
                type="button"
              >
                Fazer login
              </RedirectButton>

              <footer>Copyright &copy; Dominick Brasileiro 2021</footer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default SuccessfulRegistration;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { Container, RedirectButton } from './styles';
import DoneIcon from '../../assets/done.svg';

import { useQuery } from '../../hooks/query';

const SuccessfulSendForgotPasswordMail: React.FC = () => {
  const queryEmail = useQuery().get('email');
  const history = useHistory();

  return (
    <Container>
      <img src={DoneIcon} alt="Feito" />
      <h1>Redefinição enviada!</h1>

      <p>
        Boa, agora é só checar o e-mail que te enviamos e redefinir a sua senha.
      </p>

      <RedirectButton
        onClick={() => history.push(`/login?email=${queryEmail}`)}
        type="button"
      >
        Voltar para login
      </RedirectButton>

      <footer>Copyright &copy; Dominick Brasileiro 2021</footer>
    </Container>
  );
};

export default SuccessfulSendForgotPasswordMail;

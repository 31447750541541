import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 20;

  @media (max-width: 960px) {
    right: 50%;
    transform: translateX(50%);
  }
`;

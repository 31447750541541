import styled, { css } from 'styled-components';
import { Button } from '../../styles/Button';

export const Container = styled.div`
  > header {
    > div {
      display: flex;
      max-width: 1186px;
      margin: 0 auto;
      padding: 40px 20px;

      @media (max-width: 640px) {
        flex-direction: column;
      }
    }

    width: 100%;
    background-color: var(--color-gray);

    nav {
      ul {
        display: flex;
        list-style: none;
        font-size: 18px;

        li + li {
          margin-left: 48px;
        }

        a {
          color: inherit;
          text-decoration: none;
          transition: 0.1s;
          padding: 4px;

          &:hover {
            text-decoration: underline;
            color: var(--text-subtitle);
          }
        }
      }

      @media (max-width: 640px) {
        display: flex;
        align-items: center;

        margin-top: 32px;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
  }
`;

export const HeaderContent = styled.div`
  flex: 1;

  h1 {
    color: #fff;
    max-width: 350px;
  }

  h2 {
    color: var(--text-subtitle);
    max-width: 400px;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const GroupDetailsContainer = styled.div`
  margin-bottom: 12px;

  > div + div {
    margin-top: 8px;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

export const ProductsContainer = styled.div`
  max-width: 1186px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px #0002;

  footer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 8px 0;

      font-size: 20px;
    }

    @media (max-width: 960px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
`;

export const OptionButton = styled.a`
  display: flex;
  justify-content: center;

  padding: 12px 24px;
  margin: 4px 8px 0 0;
  border-radius: 4px;
  outline: 0;
  border: 0;

  color: #fff;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;

  background-color: var(--color-green);
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--color-green-hover);
  }

  svg {
    font-size: 20px;
    margin-left: 8px;
  }
`;

export const ProductsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

interface ProductProps {
  isSelected?: boolean;
  isLoading?: boolean;
}

export const Product = styled.div<ProductProps>`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  padding: 14px;
  border: 1px solid #0002;
  box-shadow: 0 0 5px #0002;

  ${props =>
    props.isSelected &&
    css`
      border-color: var(--color-green-hover);
      box-shadow: 0 0 5px var(--color-green-hover);
    `}

  header {
    display: flex;
    align-items: center;

    img {
      border-radius: 8px;
      margin-right: 8px;
      max-width: 72px;
    }
  }
  > div,
  > main > div {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }

  a {
    font-style: italic;
    color: inherit;
    margin-left: 4px;
    text-decoration: underline;
  }

  select {
    padding: 8px;
    margin-left: 8px;
    width: 55%;
    border: 1px solid #0005;
    background-color: #fff;
  }

  ${props =>
    !props.isSelected &&
    props.isLoading &&
    css`
      img {
        filter: grayscale(1);
      }

      button {
        background-color: #dcdce5;
        color: #9c98a6;
        cursor: not-allowed;

        &:hover {
          background-color: #dcdce5;
        }
      }
    `}
`;

export const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #0005;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
`;

interface CounterButtonProps {
  action: 'decrease' | 'increase';
}

export const CounterButton = styled.button<CounterButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 32px;

  border: none;
  outline: 0;

  transition: background-color 0.1s;

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    props.action === 'increase'
      ? css`
          background-color: var(--color-green);

          &:hover {
            background-color: var(--color-green-hover);
          }

          &:disabled {
            &:hover {
              background-color: var(--color-green);
            }
          }
        `
      : css`
          background-color: var(--color-delete);

          &:hover {
            background-color: var(--color-delete-hover);
          }

          &:disabled {
            &:hover {
              background-color: var(--color-delete);
            }
          }
        `}
`;

export const SubmitButton = styled(Button)`
  padding: 24px 0;
  font-size: 18px;
`;

import React, { useCallback, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../components/Input';

import logoImg from '../../assets/logo.png';

import {
  Container,
  Content,
  AnimationContainer,
  SubmitButton,
  BackToLoginContainer,
  Background,
} from './styles';

import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';

interface ForgotPasswordFormData {
  email: string;
}

const ForgotPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const { addToast } = useToast();

  const handleSubmit = useCallback(
    async (data: ForgotPasswordFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Formato inválido')
            .required('E-mail é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api.post('users/password/forgot', {
          email: data.email,
        });

        history.push(`/forgot-password/success?email=${data.email}`);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Usuário não encontrado.',
          });
        }
        setLoading(false);
      }
    },
    [history, addToast],
  );

  return (
    <Container>
      <Content>
        <AnimationContainer>
          <h1>Eita, esqueceu sua senha?</h1>

          <p>Não esquenta, vamos dar um jeito nisso.</p>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input name="email" type="email" placeholder="E-mail" />

            <SubmitButton type="submit" disabled={isLoading}>
              Enviar instruções
            </SubmitButton>
          </Form>

          <BackToLoginContainer>
            <Link to="/login">Voltar para login</Link>
          </BackToLoginContainer>
        </AnimationContainer>
      </Content>

      <Background>
        <img src={logoImg} alt="Jana Jô Moda Empresarial &amp; Universitária" />
      </Background>
    </Container>
  );
};

export default ForgotPassword;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react';
import { Props as ReactInputMaskProps } from 'react-input-mask';

import { useField } from '@unform/core';

import { InputContainer } from './styles';

interface InputMaskProps extends ReactInputMaskProps {
  name: string;
}

const InputMask: React.FC<InputMaskProps> = ({ name, ...rest }) => {
  const inputRef = useRef(null);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);
  return (
    <InputContainer
      ref={inputRef}
      defaultValue={defaultValue}
      errored={Number(!!error)}
      {...rest}
    />
  );
};

export default InputMask;

import React, { useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import Input from '../../components/Input';

import logoImg from '../../assets/logo.png';

import {
  Container,
  Background,
  Content,
  AnimationContainer,
  SubmitButton,
  RegisterContainer,
} from './styles';

import { useAuth } from '../../hooks/auth';
import { useQuery } from '../../hooks/query';

import getValidationErrors from '../../utils/getValidationErrors';
import { useToast } from '../../hooks/toast';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setLoading] = useState(false);

  const { signIn } = useAuth();
  const { addToast } = useToast();
  const queryEmail = useQuery().get('email');

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Formato inválido')
            .required('E-mail é obrigatório'),
          password: Yup.string().required('Senha é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'E-mail ou senha incorretos.',
          });
        }

        setLoading(false);
      }
    },
    [signIn, addToast],
  );

  return (
    <Container>
      <Background>
        <img src={logoImg} alt="Jana Jô Moda Empresarial &amp; Universitária" />
      </Background>

      <Content>
        <AnimationContainer>
          <h1>Fazer login</h1>

          <Form ref={formRef} onSubmit={handleSubmit}>
            <Input
              name="email"
              type="email"
              placeholder="E-mail"
              defaultValue={queryEmail || undefined}
            />
            <Input name="password" type="password" placeholder="Senha" />

            <Link to="/forgot-password">Esqueci minha senha</Link>

            <SubmitButton type="submit" disabled={isLoading}>
              Entrar
            </SubmitButton>
          </Form>

          <RegisterContainer>
            <span>Não tem conta?</span>

            <Link to="/signup">Cadastre-se</Link>
          </RegisterContainer>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignIn;

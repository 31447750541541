import React, { createContext, useContext, useCallback, useState } from 'react';
import jwt from 'jsonwebtoken';

import api from '../services/api';

interface IUser {
  name: string;
  email: string;
  group_id: string;
}

interface AuthState {
  token: string;
  user: IUser;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface SignUpData {
  name: string;
  email: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signUp(data: SignUpData): Promise<void>;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  joinGroup(group_code: string): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@JanaJo:token');
    const user = localStorage.getItem('@JanaJo:user');

    if (token && user) {
      const decoded = jwt.decode(token) as { exp: number };

      const expirationTimestamp = decoded.exp * 1000;

      if (Date.now() > expirationTimestamp) {
        return {} as AuthState;
      }

      return { token, user: JSON.parse(user) };
    }

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('users/sessions', {
      email,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@JanaJo:token', token);
    localStorage.setItem('@JanaJo:user', JSON.stringify(user));

    api.defaults.headers.common.Authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const signUp = useCallback(async ({ name, email, password }) => {
    await api.post('users', {
      name,
      email,
      password,
    });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@JanaJo:token');
    localStorage.removeItem('@JanaJo:user');

    delete api.defaults.headers.common.Authorization;

    setData({} as AuthState);
  }, []);

  const joinGroup = useCallback(
    async (group_code: string) => {
      const response = await api.post('/groups/join', {
        code: group_code,
      });

      const { group_id } = response.data;

      const updatedUser = { ...data.user, group_id };

      setData({
        token: data.token,
        user: updatedUser,
      });

      localStorage.setItem('@JanaJo:user', JSON.stringify(updatedUser));
    },
    [data],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signUp, signOut, joinGroup }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };

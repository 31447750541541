import styled, { keyframes } from 'styled-components';
import { Button } from '../../styles/Button';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  background-color: var(--color-gray);

  > * {
    animation: ${fadeIn} 0.68s;
  }

  h1 {
    color: var(--color-background);
    font-size: 48px;
    margin-bottom: 24px;
    text-align: center;
  }

  p {
    text-align: center;
    color: var(--text-secondary);
    max-width: 390px;
  }

  footer {
    margin-top: 60px;
    color: var(--text-secondary);
  }

  @media (max-width: 960px) {
    h1 {
      font-size: 32px;
      margin: 32px;
    }
  }
`;

export const RedirectButton = styled(Button)`
  margin-top: 40px;
  padding: 12px 36px;
  color: #333;

  background-color: var(--color-yellow);

  &:hover {
    background-color: var(--color-yellow-hover);
  }
`;

export const NewOrderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;

  > a {
    font-weight: 600;
    color: var(--text-secondary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  text:
    | 'created'
    | 'errored'
    | 'processing'
    | 'authorized'
    | 'paid'
    | 'refunded'
    | 'waiting_payment'
    | 'pending_refund'
    | 'refused'
    | 'errored';
}

export const Container = styled.span<ContainerProps>`
  text-transform: capitalize;
  font-weight: 500;

  ${props => {
    switch (props.text) {
      case 'processing':
        return css`
          color: ${shade(0.2, '#d35400')};
        `;

      case 'waiting_payment':
        return css`
          color: #d35400;
        `;

      case 'paid':
        return css`
          color: #2d6930;
        `;

      case 'refused':
      case 'errored':
        return css`
          color: #d80700;
        `;

      default:
        return css`
          color: ${shade(0.2, '#d35400')};
        `;
    }
  }};
`;

import React from 'react';
import { Link } from 'react-router-dom';

import { Container, RedirectButton, NewOrderContainer } from './styles';
import DoneAlertIcon from '../../assets/done-alert.svg';

import { useQuery } from '../../hooks/query';

const Boleto: React.FC = () => {
  const boleto_url = useQuery().get('url');

  return (
    <Container>
      <img src={DoneAlertIcon} alt="Feito" />
      <h1>Aguardando pagamento...</h1>

      <p>
        Recebemos o seu pedido! Agora é só pagar o boleto abaixo e aguardar.
      </p>

      <p>
        PS: Não pague o boleto após o vencimento. O processamento do pagamento
        pode levar até 3 dias úteis.
      </p>
      <a href={boleto_url || '#'} target="_blank" rel="noreferrer">
        <RedirectButton type="button">Visualizar boleto</RedirectButton>
      </a>
      <NewOrderContainer>
        <Link to="/">Cadastrar novo pedido</Link>
      </NewOrderContainer>
      <footer>Copyright &copy; Dominick Brasileiro 2021</footer>
    </Container>
  );
};

export default Boleto;

import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import SuccessfulRegistration from '../pages/SuccessfulRegistration';
import VerifyAccount from '../pages/VerifyAccount';
import ForgotPassword from '../pages/ForgotPassword';
import SuccessfulSendForgotPasswordMail from '../pages/SuccessfulSendForgotPasswordMail';
import ResetPassword from '../pages/ResetPassword';
import JoinGroup from '../pages/JoinGroup';
import Orders from '../pages/Orders';
import Products from '../pages/Products';
import Checkout from '../pages/Checkout';
import SuccessfulCheckoutOrder from '../pages/SuccessfulCheckoutOrder';
import Boleto from '../pages/Boleto';
import Instructions from '../pages/Instructions';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Products} isPrivate />
      <Route path="/login" component={SignIn} />
      <Route path="/signup" exact component={SignUp} />

      <Route
        path="/signup/successful-registration"
        component={SuccessfulRegistration}
      />

      <Route path="/signup/confirm" component={VerifyAccount} />
      <Route path="/forgot-password" exact component={ForgotPassword} />

      <Route
        path="/forgot-password/success"
        component={SuccessfulSendForgotPasswordMail}
      />

      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/join-group" exact component={JoinGroup} isPrivate />
      <Route path="/orders" exact component={Orders} isPrivate />
      <Route path="/orders/checkout" exact component={Checkout} isPrivate />

      <Route
        path="/orders/successful-checkout"
        component={SuccessfulCheckoutOrder}
        isPrivate
      />

      <Route path="/orders/boleto" component={Boleto} isPrivate />
      <Route path="/instructions" component={Instructions} isPrivate />
    </Switch>
  );
};

export default Routes;

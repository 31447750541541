import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Container, SubmitButton, BackToLoginContainer } from './styles';
import Input from '../../components/Input';
import DoneIcon from '../../assets/done.svg';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import getValidationErrors from '../../utils/getValidationErrors';

interface JoinGroupFormData {
  code: string;
}

const SuccessfulRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);

  const { user, signOut, joinGroup } = useAuth();
  const { addToast } = useToast();

  const firstname = useMemo(() => {
    return user.name.split(' ')[0];
  }, [user.name]);

  const handleSubmit = useCallback(
    async (data: JoinGroupFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object().shape({
          code: Yup.string().required('Código da Turma é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await joinGroup(data.code);

        addToast({
          type: 'success',
          title: 'Você entrou na turma com sucesso.',
        });

        history.push('/instructions');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else {
          addToast({
            type: 'error',
            title: 'Turma não encontrada.',
          });
        }

        setLoading(false);
      }
    },
    [history, joinGroup, addToast],
  );

  return (
    <Container>
      <img src={DoneIcon} alt="Feito" />
      <h1>Bem-vindo(a), {firstname}!</h1>

      <p>
        Falta pouco! Agora tudo que você precisa fazer é entrar em uma turma.
      </p>

      <Form onSubmit={handleSubmit} ref={formRef}>
        <Input name="code" type="text" placeholder="Código da Turma" />

        <SubmitButton type="submit" disabled={isLoading}>
          Entrar na turma
        </SubmitButton>
      </Form>

      <BackToLoginContainer>
        <Link to="/login" onClick={signOut}>
          Voltar para login
        </Link>
      </BackToLoginContainer>

      <footer>Copyright &copy; Dominick Brasileiro 2021</footer>
    </Container>
  );
};

export default SuccessfulRegistration;

import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactLoading from 'react-loading';

import { parseISO, isBefore, startOfDay, endOfDay } from 'date-fns';
import {
  Container,
  HeaderContent,
  LoadingContainer,
  OrdersContainer,
  OrdersGrid,
  Order,
  OrderContent,
  OrderGroup,
  OrderAmount,
  OrderStatus,
  BoletoButton,
  NoOrdersText,
} from './styles';

import StatusText from './StatusText';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import formatBRL from '../../utils/formatBRL';

interface OrderItem {
  id: string;
  quantity: number;
  name: string;
  size: string;
}

interface Order {
  id: string;
  code: number;
  amount: number;
  status:
    | 'created'
    | 'errored'
    | 'processing'
    | 'authorized'
    | 'paid'
    | 'refunded'
    | 'waiting_payment'
    | 'pending_refund'
    | 'refused';
  transaction?: {
    payment_method: 'credit_card' | 'boleto';
    boleto_url?: string;
    boleto_expiration_date: string;
  };
  group?: {
    name: string;
  };
  order_items: OrderItem[];
}

const Orders: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [orders, setOrders] = useState<Order[]>([]);
  const history = useHistory();

  const { user, signOut } = useAuth();
  const { addToast } = useToast();

  useEffect(() => {
    async function loadOrders() {
      setLoading(true);

      try {
        const response = await api.get<Order[]>(`/me/orders`);

        setOrders(response.data);
      } catch {
        addToast({
          type: 'error',
          title:
            'Não foi possível carregar os seus pedidos. Tente novamente mais tarde.',
        });
      } finally {
        setLoading(false);
      }
    }

    loadOrders();
  }, [history, user.group_id, addToast]);

  return (
    <Container>
      <header>
        <div>
          <HeaderContent>
            <h1>Estes são os seus pedidos realizados.</h1>
            <h2>
              Estamos a disposição para eventuais dúvidas ou problemas, basta
              entrar em contato conosco e nos informar o número do seu pedido!
            </h2>
          </HeaderContent>

          <nav>
            <ul>
              <li>
                <Link to="/">Novo Pedido</Link>
              </li>

              <li>
                <Link to="/login" onClick={signOut}>
                  Sair
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {isLoading ? (
        <LoadingContainer>
          <ReactLoading type="bubbles" color="#6a6180" />
        </LoadingContainer>
      ) : (
        <>
          {orders && orders.length > 0 ? (
            <OrdersContainer>
              <OrdersGrid>
                {orders.map(order => (
                  <Order key={order.id}>
                    <OrderContent>
                      <h3>Pedido #{order.code}</h3>

                      {order.group && (
                        <OrderGroup>
                          <strong>Turma: </strong>
                          {order.group.name}
                        </OrderGroup>
                      )}

                      <div>
                        <ul>
                          {order.order_items &&
                            order.order_items.map(item => (
                              <li key={item.id}>
                                <strong>{item.quantity}x</strong> {item.name} -
                                Tamanho <strong>{item.size}</strong>;
                              </li>
                            ))}
                        </ul>
                      </div>

                      <OrderAmount>
                        <strong>Valor: </strong>
                        {formatBRL(order.amount)}
                      </OrderAmount>

                      <OrderStatus>
                        <strong>Situação: </strong>
                        <StatusText text={order.status} />
                      </OrderStatus>

                      {order.transaction?.payment_method === 'boleto' &&
                        order.status === 'waiting_payment' &&
                        isBefore(
                          startOfDay(Date.now()),
                          endOfDay(
                            parseISO(order.transaction?.boleto_expiration_date),
                          ),
                        ) && (
                          <a
                            href={order.transaction.boleto_url || '#'}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <BoletoButton type="button">
                              Visualizar boleto
                            </BoletoButton>
                          </a>
                        )}
                    </OrderContent>
                  </Order>
                ))}
              </OrdersGrid>
            </OrdersContainer>
          ) : (
            <NoOrdersText>
              Poxa... Você ainda não fez nenhum pedido :(
            </NoOrdersText>
          )}
        </>
      )}

      <footer>
        <p>Copyright &copy; Dominick Brasileiro 2021</p>
      </footer>
    </Container>
  );
};

export default Orders;

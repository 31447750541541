import { lighten } from 'polished';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-size: 15px;

  animation: ${fadeIn} 0.68s;

  color: #000 !important;
  font-family: 'Roboto', sans-serif;

  h1,
  h2 {
    color: inherit;
  }
`;

export const Content = styled.div`
  max-width: 800px;
  padding: 0 20px;

  h1 {
    text-align: center;
    font-weight: 700;
    margin-bottom: 24px;
  }
`;

export const Section = styled.section`
  & + section {
    margin-top: 24px;
  }

  h2 {
    font-weight: 600;
  }

  ul {
    list-style-position: inside;
  }

  ul ul {
    margin-left: 10px;
  }

  li,
  li + li {
    margin-top: 8px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;

  a {
    display: flex;
    justify-content: center;

    padding: 16px 80px;
    border-radius: 4px;
    outline: 0;
    border: 0;

    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;

    background-color: #232323;
    transition: background-color 0.2s;

    & + a {
      margin-left: 8px;
    }

    &:hover {
      background-color: ${lighten(0.05, '#232323')};
    }
  }
`;

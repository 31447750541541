import styled from 'styled-components';
import { shade } from 'polished';
import { Button } from '../../styles/Button';

export const Container = styled.div`
  > header {
    > div {
      display: flex;
      max-width: 1186px;
      margin: 0 auto;
      padding: 40px 20px;

      @media (max-width: 640px) {
        flex-direction: column;
      }
    }

    width: 100%;
    background-color: var(--color-gray);

    nav {
      ul {
        display: flex;
        list-style: none;
        font-size: 18px;

        li + li {
          margin-left: 48px;
        }

        a {
          color: inherit;
          text-decoration: none;
          transition: 0.1s;
          padding: 4px;

          &:hover {
            text-decoration: underline;
            color: var(--text-subtitle);
          }
        }
      }

      @media (max-width: 640px) {
        display: flex;
        align-items: center;

        margin-top: 32px;
      }
    }
  }

  > footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 32px;
  }
`;

export const HeaderContent = styled.div`
  flex: 1;

  h1 {
    color: #fff;
    max-width: 350px;
  }

  h2 {
    color: var(--text-subtitle);
    max-width: 400px;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`;

export const OrdersContainer = styled.div`
  max-width: 1186px;
  margin: 0 auto;
  margin-top: 16px;
`;

export const OrdersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

interface OrderProps {
  isSelected?: boolean;
  isLoading?: boolean;
}

export const Order = styled.div`
  position: relative;
`;

export const OrderContent = styled.div<OrderProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  padding: 16px 24px;
  background-color: #fff;
  box-shadow: 0 0 5px #0002;

  > div {
    margin: 16px 0;
    padding: 16px 0;

    border: 1px solid #0004;
    border-left: 0;
    border-right: 0;

    > ul {
      list-style-position: inside;
      margin-left: 4px;
      padding: 0;
    }
  }
`;

export const OrderGroup = styled.span`
  margin-top: 16px;
`;

export const OrderAmount = styled.span`
  margin-bottom: 16px;
`;

export const OrderStatus = styled.span`
  margin-bottom: 16px;
`;

export const BoletoButton = styled(Button)`
  background-color: #2980b9;

  &:hover {
    background-color: ${shade(0.2, '#2980b9')};
  }
  width: 100%;
`;

export const NoOrdersText = styled.p`
  max-width: 1186px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 0 5px #0002;
`;

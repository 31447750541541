import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Container, RedirectButton, NewOrderContainer } from './styles';
import DoneAlertIcon from '../../assets/done-alert.svg';

const SuccessfulCheckoutOrder: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <img src={DoneAlertIcon} alt="Feito" />
      <h1>Processando...</h1>

      <p>
        Quase lá! Nós te enviaremos um e-mail assim que o seu pedido for
        confirmado. Obrigado!
      </p>

      <RedirectButton onClick={() => history.push(`/orders`)} type="button">
        Ver meus pedidos
      </RedirectButton>

      <NewOrderContainer>
        <Link to="/">Cadastrar novo pedido</Link>
      </NewOrderContainer>

      <footer>Copyright &copy; Dominick Brasileiro 2021</footer>
    </Container>
  );
};

export default SuccessfulCheckoutOrder;

import pagarme from 'pagarme';

interface Card {
  holder_name: string;
  number: string;
  expiration_date: string;
  cvv: string;
}

export default async function generateCardHash(
  card: Card,
): Promise<string | undefined> {
  const encryption_key = process.env.REACT_APP_PAGARME_ENCRYPTION_KEY;

  if (!encryption_key) {
    throw new Error('Pagar.me encryption key is missing.');
  }

  const client = await pagarme.client.connect({
    encryption_key,
  });

  return client.security.encrypt({
    card_holder_name: card.holder_name,
    card_number: card.number,
    card_expiration_date: card.expiration_date,
    card_cvv: card.cvv,
  });
}

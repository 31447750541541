import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import queryString from 'query-string';
import { cpf } from 'cpf-cnpj-validator';

import {
  Container,
  HeaderContent,
  FormContent,
  InputGroup,
  SubmitButton,
} from './styles';
import Input from '../../components/Input';
import InputMask from '../../components/InputMask';
import Textarea from '../../components/Textarea';

import attentionIcon from '../../assets/attention.svg';

import { useAuth } from '../../hooks/auth';
import { useCart } from '../../hooks/cart';
import formatBRL from '../../utils/formatBRL';
import validateCard from '../../utils/validateCard';
import generateCardHash from '../../utils/generateCardHash';
import getValidationErrors from '../../utils/getValidationErrors';

import brazilStates from '../../assets/brazil-states.json';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';

type PaymentMethodOptions = 'credit_card' | 'boleto';

interface CheckoutFormData {
  customer: {
    name: string;
    email: string;
    cpf: string;
    phone: string;
  };

  card?: {
    holder_name: string;
    number: string;
    expiration_date: string;
    cvv: string;
  };

  address: {
    neighborhood: string;
    street: string;
    street_number: string;
    complementary?: string;
    zipcode: string;
  };

  notes: string;
}

const Checkout: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [isLoading, setLoading] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodOptions>(
    'credit_card',
  );
  const [installments, setInstallments] = useState(1);
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const { signOut } = useAuth();
  const { cart, total, clearCart } = useCart();
  const { addToast } = useToast();

  const history = useHistory();

  useEffect(() => {
    if (cart.length < 1) {
      history.push('/');
    }
  }, [cart, history]);

  const stateCities = useMemo(() => {
    const selectedState = brazilStates.states.find(
      brazilState => brazilState.initials === state,
    );

    return selectedState?.cities;
  }, [state]);

  const handleSubmit = useCallback(
    async (data: CheckoutFormData) => {
      formRef.current?.setErrors({});

      setLoading(true);

      try {
        const schema = Yup.object()
          .shape({
            customer: Yup.object().shape({
              name: Yup.string().required(),
              email: Yup.string().email().required(),
              cpf: Yup.string().required(),
              phone: Yup.string().required(),
            }),

            card: Yup.object().shape({
              holder_name:
                paymentMethod === 'credit_card'
                  ? Yup.string().required()
                  : Yup.string(),

              number:
                paymentMethod === 'credit_card'
                  ? Yup.string().required()
                  : Yup.string(),

              expiration_date:
                paymentMethod === 'credit_card'
                  ? Yup.string().required()
                  : Yup.string(),

              cvv:
                paymentMethod === 'credit_card'
                  ? Yup.string().required()
                  : Yup.string(),
            }),

            address: Yup.object().shape({
              neighborhood: Yup.string().required(),
              street: Yup.string().required(),
              street_number: Yup.string().required(),
              complementary: Yup.string(),
              zipcode: Yup.string().required(),
            }),
          })
          .test('cpfTest', 'CPF Inválido', obj => {
            if (obj?.customer?.cpf) {
              const isCpfValid = cpf.isValid(obj?.customer?.cpf);

              if (!isCpfValid) {
                return new Yup.ValidationError(
                  'CPF Inválido',
                  '',
                  'customer.cpf',
                );
              }
            }

            return true;
          });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { address, customer, card, notes } = data;

        address.zipcode = address.zipcode.replace(/[\W_]/g, '');
        customer.cpf = customer.cpf.replace(/[\W_]/g, '');
        customer.phone = `+55${customer.phone.replace(/[\W_]/g, '')}`;

        let card_hash;
        if (paymentMethod === 'credit_card' && card) {
          card.cvv = card.cvv.replace(/[\W_]/g, '');
          const validationError = await validateCard(card);

          if (validationError) {
            addToast({
              type: 'error',
              title: validationError,
            });

            setLoading(false);

            return;
          }

          card_hash = await generateCardHash(card);
        }

        const response = await api.post('orders', {
          payment_method: paymentMethod,
          installments,
          card_hash,
          customer,
          address: {
            ...address,
            state,
            city,
            complementary: data.address.complementary || undefined,
          },
          cart,
          notes,
        });

        const {
          payment_method,
          boleto_barcode,
          boleto_url,
          boleto_expiration_date,
        } = response.data.transaction;

        if (payment_method === 'credit_card') {
          history.push('/orders/successful-checkout');
        } else {
          const { order } = response.data;

          const params = queryString.stringify({
            order_number: order.code,
            barcode: boleto_barcode,
            url: boleto_url,
            expiration_date: boleto_expiration_date,
          });

          history.push(`/orders/boleto?${params}`);
        }

        clearCart();
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Dados inválidos.',
          });
        } else {
          addToast({
            type: 'error',
            title:
              'Não foi possível finalizar o pedido. Verifique a sua conexão e tente novamente.',
          });
        }

        setLoading(false);
      }
    },
    [
      paymentMethod,
      installments,
      state,
      city,
      cart,
      addToast,
      history,
      clearCart,
    ],
  );

  return (
    <Container>
      <header>
        <div>
          <HeaderContent>
            <h1>Finalize seu pedido</h1>
            <h2>
              Quase lá! Preencha o formulário abaixo com os seus dados e
              finalize o seu pedido.
            </h2>
          </HeaderContent>

          <nav>
            <ul>
              <li>
                <Link to="/orders">Meus Pedidos</Link>
              </li>

              <li>
                <Link to="/login" onClick={signOut}>
                  Sair
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormContent>
          <fieldset>
            <legend>Dados Cadastrais</legend>

            <label htmlFor="name">
              <span>Nome completo</span>
              <Input
                name="customer.name"
                placeholder="Ex: João da Silva"
                id="name"
              />
            </label>

            <label htmlFor="email">
              <span>E-mail</span>
              <Input
                name="customer.email"
                placeholder="Ex: joao.silva@gmail.com"
                id="email"
              />
            </label>

            <label htmlFor="cpf">
              <span>CPF</span>
              <InputMask
                name="customer.cpf"
                placeholder="XXX.XXX.XXX-XX"
                id="cpf"
                mask="999.999.999-99"
              />
            </label>

            <label htmlFor="phone">
              <span>Celular</span>
              <InputMask
                name="customer.phone"
                placeholder="(XX) XXXXXXXXX"
                id="phone"
                mask="(99) 999999999"
              />
            </label>
          </fieldset>

          <fieldset>
            <legend>Forma de Pagamento</legend>

            <label htmlFor="payment_method">
              <span>Método de pagamento</span>
              <select
                name="payment_method"
                id="payment_method"
                value={paymentMethod}
                onChange={e =>
                  setPaymentMethod(e.target.value as PaymentMethodOptions)
                }
              >
                <option value="credit_card">Cartão de Crédito</option>
                <option value="boleto">Boleto Bancário</option>
              </select>
            </label>

            {paymentMethod === 'credit_card' && (
              <>
                <label htmlFor="installments">
                  <span>Parcelas</span>
                  <select
                    name="installments"
                    id="installments"
                    value={installments}
                    onChange={e =>
                      setInstallments(parseInt(e.target.value, 10))
                    }
                  >
                    <option value={1}>1x de {formatBRL(total)}</option>
                    {total / 2 >= 3000 && (
                      <option value={2}>
                        2x de {formatBRL(Math.floor(total / 2))}
                      </option>
                    )}
                    {total / 3 >= 3000 && (
                      <option value={3}>
                        3x de {formatBRL(Math.floor(total / 3))}
                      </option>
                    )}
                  </select>
                </label>

                <InputGroup>
                  <label htmlFor="holder_name">
                    <span>Nome no cartão</span>
                    <Input
                      name="card.holder_name"
                      id="holder_name"
                      placeholder="Ex: MARIANA S CARDOSO"
                    />
                  </label>

                  <label htmlFor="number">
                    <span>Número do cartão</span>
                    <Input
                      name="card.number"
                      id="number"
                      placeholder="XXXXXXXXXXXXXXXX"
                    />
                  </label>
                </InputGroup>

                <InputGroup>
                  <label htmlFor="expiration_date">
                    <span>Validade</span>
                    <InputMask
                      name="card.expiration_date"
                      id="expiration_date"
                      placeholder="XX/XX"
                      mask="99/99"
                    />
                  </label>

                  <label htmlFor="cvv">
                    <span>Código de segurança</span>
                    <InputMask
                      name="card.cvv"
                      id="cvv"
                      placeholder="XXX"
                      mask="999"
                    />
                  </label>
                </InputGroup>
              </>
            )}
          </fieldset>

          <fieldset>
            <legend>Endereço de Cobrança</legend>

            <label htmlFor="zipcode">
              <span>CEP</span>
              <InputMask
                name="address.zipcode"
                placeholder="XXXXX-XXX"
                id="zipcode"
                mask="99999-999"
              />
            </label>

            <InputGroup>
              <label htmlFor="state">
                <span>Estado</span>
                <select
                  name="state"
                  id="state"
                  value={state}
                  onChange={e => setState(e.target.value)}
                >
                  <option value="" disabled>
                    Selecione o estado
                  </option>

                  {brazilStates.states.map((brazilState, index) => (
                    <option
                      key={`${index}_${brazilState}`}
                      value={brazilState.initials}
                    >
                      {brazilState.name}
                    </option>
                  ))}
                </select>
              </label>

              <label htmlFor="city">
                <span>Cidade</span>
                <select
                  name="city"
                  id="city"
                  value={city}
                  onChange={e => setCity(e.target.value)}
                  disabled={!state}
                >
                  <option value="" disabled>
                    Selecione a cidade
                  </option>

                  {stateCities &&
                    stateCities.map((stateCity, index) => (
                      <option key={`${index}_${stateCity}`} value={stateCity}>
                        {stateCity}
                      </option>
                    ))}
                </select>
              </label>
            </InputGroup>

            <InputGroup>
              <label htmlFor="neighborhood">
                <span>Bairro</span>
                <Input
                  name="address.neighborhood"
                  id="neighborhood"
                  placeholder="Ex: Centro"
                />
              </label>

              <label htmlFor="street">
                <span>Rua</span>
                <Input
                  name="address.street"
                  id="street"
                  placeholder="Ex: Rua das Palmeiras"
                />
              </label>
            </InputGroup>

            <InputGroup>
              <label htmlFor="street_number">
                <span>Número da residência</span>
                <Input
                  name="address.street_number"
                  id="street_number"
                  placeholder="Ex: 42"
                />
              </label>

              <label htmlFor="complementary">
                <span>Complemento</span>
                <Input
                  name="address.complementary"
                  id="complementary"
                  placeholder="Ex: apt. 102"
                />
              </label>
            </InputGroup>
          </fieldset>

          <fieldset>
            <legend>Informações Adicionais</legend>

            <label htmlFor="name">
              <span>Alguma observação?</span>
              <Textarea
                name="notes"
                placeholder="Ex: cores, nome a ser bordado, etc."
                id="notes"
                rows={3}
              />
            </label>
          </fieldset>

          <footer>
            <div>
              <img src={attentionIcon} alt="Atenção!" />
              <div>
                <strong>Importante!</strong>
                <p>Preencha todos os dados corretamente.</p>
              </div>
            </div>

            <SubmitButton disabled={!city || isLoading}>
              Finalizar pedido
            </SubmitButton>
          </footer>
        </FormContent>
      </Form>

      <footer>
        <p>Copyright &copy; Dominick Brasileiro 2021</p>
      </footer>
    </Container>
  );
};

export default Checkout;

import React from 'react';
import { Link } from 'react-router-dom';

import { Container, BackToLoginContainer } from './styles';
import DoneIcon from '../../assets/done.svg';

const SuccessfulRegistration: React.FC = () => {
  return (
    <Container>
      <img src={DoneIcon} alt="Feito" />
      <h1>Cadastro concluído</h1>

      <p>
        Você está quase lá! Agora é só checar o e-mail que te enviamos e
        confirmar a sua conta.
      </p>

      <BackToLoginContainer>
        <Link to="/login">Voltar para login</Link>
      </BackToLoginContainer>

      <footer>Copyright &copy; Dominick Brasileiro 2021</footer>
    </Container>
  );
};

export default SuccessfulRegistration;

import React, { useMemo } from 'react';

import { Container } from './styles';

interface StatusTextProps {
  text:
    | 'created'
    | 'errored'
    | 'processing'
    | 'authorized'
    | 'paid'
    | 'refunded'
    | 'waiting_payment'
    | 'pending_refund'
    | 'refused'
    | 'errored';
}

const StatusText: React.FC<StatusTextProps> = ({ text }) => {
  const formattedText = useMemo(() => {
    switch (text) {
      case 'created':
      case 'processing':
        return 'processando';

      case 'waiting_payment':
        return 'aguardando pagamento';

      case 'paid':
        return 'pago';

      case 'refused':
        return 'recusado';

      case 'errored':
        return 'erro';

      default:
        return text;
    }
  }, [text]);

  return <Container text={text}>{formattedText}</Container>;
};

export default StatusText;
